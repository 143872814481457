import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CashflowService } from '../../../services/cashflow.service';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-cash-flow-analytics',
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.css'
})
export class CashflowAnalyticsComponent implements OnInit {
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;


  months=["Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre"];
  year="2024";
  years=["2020","2021","2022","2023","2024"];
  list=[];
  constructor(
    private cashflowService:CashflowService,
  ) {
    
   }

  chartData=[];
  chartLabels=[];
  chartOptions = {
    responsive: true
  };

  total_in=0;
  total_out=0;

  ngOnInit(): void {
    this.getItems();
    
  }

  getItems(){
    this.chartLabels=[];
    let data_in=[];
    let data_out=[];
    this.chartData=[];
    this.total_in=0;
    this.total_out=0;

    this.cashflowService.getAnalytics(this.year).subscribe((items)=>{
      


      this.list=items;

      for(let i=0;i<this.list.length;i++){
        var r=this.list[i];
        data_in.push(r.total_in);
        data_out.push(r.total_out);
        this.chartLabels.push(this.months[r.month-1]);
        this.total_in=this.total_in+parseFloat(r.total_in);
        this.total_out=this.total_out+parseFloat(r.total_out);
        
        
          
      }
      
  
      
      this.chartData.push({"data":data_in,"label":"Entrate"});
      this.chartData.push({"data":data_out,"label":"Uscite"});
      
      this.charts.forEach((child) => {
        child.chart.update()
      });
      
    });
  }

}
